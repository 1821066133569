[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select:not([size]) {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .83rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

.dark input[type="file"]::file-selector-button {
  color: #fff;
  background: #4b5563;
}

.dark input[type="file"]::file-selector-button:hover {
  background: #6b7280;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #6b7280;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #6b7280;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

.toggle-bg:after {
  content: "";
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  background: #fff;
  border-width: 1px;
  border-color: #d1d5db;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  position: absolute;
  top: .125rem;
  left: .125rem;
}

input:checked + .toggle-bg:after {
  border-color: #fff;
  transform: translateX(100%);
}

input:checked + .toggle-bg {
  background: #1c64f2;
  border-color: #1c64f2;
}

.tooltip-arrow, .tooltip-arrow:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
  border-style: solid;
  border-color: #e5e7eb;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-right-width: 1px;
}

.tooltip[data-popper-placement^="top"] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltip-arrow {
  left: -4px;
}

.tooltip.invisible > .tooltip-arrow:before {
  visibility: hidden;
}

[data-popper-arrow], [data-popper-arrow]:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  background: inherit;
  width: 9px;
  height: 9px;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #4b5563;
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #4b5563;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

[role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
  visibility: hidden;
}

:root {
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: clamp(.9rem, .321429vw + .835714rem, 1.125rem);
  line-height: 1.75;
}

p {
  margin-bottom: .5rem;
}

a {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 2rem;
  }
}

.h1 em, .h2 em, .h3 em, .h4 em, .h5 em, .h6 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.d1 {
  font-size: 7rem;
}

.h1 {
  letter-spacing: -.03em;
  margin-bottom: 2rem;
  font-size: 2.76rem;
  line-height: 1.25;
}

@media (width >= 767px) {
  .h1 {
    margin-bottom: 3rem;
  }
}

.h2 {
  letter-spacing: -.03em;
  font-size: 2.21rem;
  line-height: 1.25;
}

.h3 {
  letter-spacing: 0;
  font-size: 1.66rem;
  line-height: 1.5;
}

.h4 {
  letter-spacing: 0;
  font-size: 1.38rem;
  line-height: 1.5;
}

.h5, .h6 {
  letter-spacing: 0;
  font-size: 1.1rem;
  line-height: 1.75;
}

.text--info {
  letter-spacing: 0;
  font-size: .83rem;
  font-weight: 500;
  line-height: 1.75;
}

.text--specs {
  font-size: 13.5px;
  font-weight: 500;
}

.text--specs-lg {
  font-size: 15px;
  font-weight: 500;
}

a {
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

[class*="theme--"] {
  background-color: var(--c_bg);
  color: var(--c_body);
}

[class*="theme--"] h1:not([class*="text-"]), [class*="theme--"] h2:not([class*="text-"]), [class*="theme--"] h3:not([class*="text-"]), [class*="theme--"] h4:not([class*="text-"]), [class*="theme--"] h5:not([class*="text-"]), [class*="theme--"] h6:not([class*="text-"]), [class*="theme--"] .h1:not([class*="text-"]), [class*="theme--"] .h2:not([class*="text-"]), [class*="theme--"] .h3:not([class*="text-"]), [class*="theme--"] .h4:not([class*="text-"]), [class*="theme--"] .h5:not([class*="text-"]), [class*="theme--"] .h6:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] h1:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] h2:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] h3:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] h4:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] h5:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] h6:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] .h1:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] .h2:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] .h3:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] .h4:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] .h5:not([class*="text-"]) em:not([class*="text-"]), [class*="theme--"] .h6:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.theme--white {
  --c_bg: #f1f4ff;
  --c_body: #475467;
  --c_headline: #101828;
  --c_accent: #2766ff;
  --c_subtle: #98a2b3;
}

.theme--light {
  --c_bg: #ebf0ff;
  --c_body: #475467;
  --c_headline: #101828;
  --c_accent: #2766ff;
  --c_subtle: #98a2b3;
}

.theme--brand {
  --c_bg: #2766ff;
  --c_body: #d6e2ff;
  --c_headline: #fcfcfd;
  --c_accent: #16db93;
  --c_subtle: #adc5ff;
}

.theme--dark {
  --c_bg: #101828;
  --c_body: #d6e2ff;
  --c_headline: #fcfcfd;
  --c_accent: #16db93;
  --c_subtle: #adc5ff;
}

picture {
  max-width: 100%;
  height: auto;
  display: block;
}

picture img {
  height: auto;
}

.container {
  max-width: calc(var(--container-max-w)  + 2 * var(--container-gutter));
  padding-inline: var(--container-gutter);
  width: 100%;
  margin-inline: auto;
}

.container--narrow {
  --container-max-w: 780px;
}

.container--wide {
  --container-max-w: 1440px;
  --container-gutter: inherit;
}

.container--full {
  --container-max-w: 100%;
  --container-gutter: 0;
}

.grid-main {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1.5rem;
  display: grid;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.section {
  z-index: 1;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

.section--py-l {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.section__bg img {
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.section--hero {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.list-check li {
  padding-left: 1.75em;
  list-style-type: none;
}

.list-check li:before {
  content: "";
  width: 1.75em;
  margin-left: -1.75em;
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
}

[class*="hint--"] {
  border-bottom: 2px dotted #b0b4b8;
}

[class*="hint--"]:before {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

[class*="hint--"]:after {
  text-align: center;
  border-radius: .5rem;
  width: 10rem;
  white-space: normal !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(67 69 96 / var(--tw-text-opacity)) !important;
  letter-spacing: 0 !important;
  text-shadow: none !important;
  padding: .75rem !important;
  font-family: inherit !important;
  font-size: .75rem !important;
  line-height: 1.25 !important;
  box-shadow: 0 12px 49px -15px #5c5f84 !important;
}

.hint--bottom.hint--bottomright:before {
  left: calc(1rem - 6px);
}

.hint--bottom.hint--bottomright:after {
  text-align: left;
  left: .25rem;
  transform: translateX(0%);
}

.hint--bottom.hint--bottomright:hover:after {
  transform: translateX(0)translateY(8px);
}

.btn {
  text-align: center;
  border: 1px solid #0000;
  border-radius: .75rem;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  padding: .75rem 2rem;
  font-weight: 800;
  line-height: 1.25;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
  display: inline-flex;
}

@media (width >= 600px) {
  .btn {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.btn .fas {
  font-size: 1.5em;
}

.btn i {
  aspect-ratio: 1;
}

.btn--sm {
  letter-spacing: 0;
  padding: .5rem 1.25rem;
  font-size: .83rem;
  line-height: 1.75;
}

.btn--primary {
  --tw-bg-opacity: 1;
  background-color: rgb(19 194 130 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(241 244 255 / var(--tw-text-opacity));
}

.btn--primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 219 147 / var(--tw-bg-opacity));
}

.btn--primary:active {
  --tw-bg-opacity: 1;
  background-color: rgb(15 149 99 / var(--tw-bg-opacity));
}

.btn--secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(235 240 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

.btn--secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(39 102 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
}

.btn--secondary:active {
  --tw-border-opacity: 1;
  border-color: rgb(0 46 163 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
}

.btn--outline {
  --tw-border-opacity: 1;
  border-color: rgb(39 102 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  background-color: #0000;
}

.btn--outline:hover {
  --tw-border-opacity: 1;
  border-color: rgb(39 102 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(241 244 255 / var(--tw-text-opacity));
}

.btn--outline:active {
  --tw-border-opacity: 1;
  border-color: rgb(0 46 163 / var(--tw-border-opacity));
  background-color: #0000;
}

.btn--outline-on-dark {
  --tw-border-opacity: 1;
  border-color: rgb(252 252 253 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
  background-color: #0000;
}

.btn--outline-on-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(92 138 255 / var(--tw-bg-opacity));
}

.btn--outline-on-dark:active {
  --tw-border-opacity: 1;
  border-color: rgb(234 236 240 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(234 236 240 / var(--tw-text-opacity));
}

.btn--alt {
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(241 244 255 / var(--tw-text-opacity));
}

.btn--alt:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(92 138 255 / var(--tw-bg-opacity));
}

.btn--alt:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 69 245 / var(--tw-bg-opacity));
}

.btn--altlight {
  --tw-bg-opacity: 1;
  background-color: rgb(92 138 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(241 244 255 / var(--tw-text-opacity));
}

.btn--altlight:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
}

.btn--altlight:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 69 245 / var(--tw-bg-opacity));
}

.btn--tertiary {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  padding: .25rem 0;
  font-weight: 400;
}

.btn--tertiary:hover {
  --tw-text-opacity: 1;
  color: rgb(0 69 245 / var(--tw-text-opacity));
}

.btn--tertiary:active {
  --tw-text-opacity: 1;
  color: rgb(0 46 163 / var(--tw-text-opacity));
}

.btn--wide {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (width >= 600px) {
  .btn--wide {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.btn--link {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  background-color: #0000;
  border-color: #0000;
}

.btn--link:hover {
  --tw-text-opacity: 1;
  color: rgb(0 69 245 / var(--tw-text-opacity));
}

.btn--link:active {
  --tw-text-opacity: 1;
  color: rgb(0 46 163 / var(--tw-text-opacity));
}

.btn--link-on-dark {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
  background-color: #0000;
  border-color: #0000;
}

.btn--link-on-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(173 197 255 / var(--tw-text-opacity));
}

.btn--link-on-dark:active {
  --tw-text-opacity: 1;
  color: rgb(208 213 221 / var(--tw-text-opacity));
}

.btn--inline {
  padding: 0;
}

.tabs {
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  border-radius: 1rem;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.tabs__content {
  flex-wrap: wrap;
  gap: 3rem;
  padding: 3rem;
  display: flex;
}

@media (width >= 991px) {
  .tabs__content {
    flex-flow: row-reverse;
    gap: 8rem;
    padding: 6rem;
  }
}

.tabs--full {
  background-color: #0000;
}

.tabs--full .tabs__content {
  padding-left: 0;
  padding-right: 0;
}

.tabs__nav {
  --tw-bg-opacity: 1;
  background-color: rgb(235 240 255 / var(--tw-bg-opacity));
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  justify-content: space-around;
  align-self: center;
  width: 100%;
  max-width: 100%;
  display: flex;
  overflow-x: auto;
}

@media (width >= 991px) {
  .tabs__nav {
    width: auto;
  }
}

.tabs__nav li {
  cursor: pointer;
  background-color: inherit;
  --tw-text-opacity: 1;
  color: rgb(151 154 191 / var(--tw-text-opacity));
  padding-left: .5rem;
  padding-right: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
  position: relative;
}

@media (width >= 600px) {
  .tabs__nav li {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.tabs__nav li:first-child {
  border-bottom-left-radius: 1rem;
  padding-left: 1rem;
}

@media (width >= 600px) {
  .tabs__nav li:first-child {
    padding-left: 3rem;
  }
}

.tabs__nav li:last-child {
  border-bottom-right-radius: 1rem;
  padding-right: 1rem;
}

@media (width >= 600px) {
  .tabs__nav li:last-child {
    padding-right: 3rem;
  }
}

.tabs__nav li.swiper-pagination-bullet-active, .tabs__nav li:hover {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
}

.tabs__nav li.swiper-pagination-bullet-active span:after, .tabs__nav li:hover span:after {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tabs__nav li span {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: block;
  position: relative;
}

@media (width >= 600px) {
  .tabs__nav li span {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.tabs__nav li span:after {
  transform-origin: 0;
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
  content: "";
  border-radius: .125rem;
  width: 100%;
  height: 2px;
  transition-property: transform;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  display: block;
  position: absolute;
  bottom: 0;
}

.accordion__item {
  margin-bottom: 2rem;
}

.accordion__trigger {
  margin-bottom: 0;
  padding-left: 4rem;
  position: relative;
}

.accordion__trigger:before {
  --tw-bg-opacity: 1;
  background-color: rgb(92 138 255 / var(--tw-bg-opacity));
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.90625 16H22.0967' stroke='%23F1F4FF' stroke-width='2'/%3E%3Cpath d='M16 9.90625L16 22.0967' stroke='%23F1F4FF' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
  position: absolute;
  left: 0;
}

.accordion__item.is-active .accordion__trigger:before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.90625 16H22.0967' stroke='%23F1F4FF' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.accordion__trigger:hover:before {
  --tw-bg-opacity: 1;
  background-color: rgb(241 244 255 / var(--tw-bg-opacity));
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.90625 16H22.0967' stroke='%232766FF' stroke-width='2'/%3E%3Cpath d='M16 9.90625L16 22.0967' stroke='%232766FF' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.accordion__item.is-active .accordion__trigger:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.90625 16H22.0967' stroke='%232766FF' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.accordion__panel {
  padding-left: 4rem;
  transition-property: height;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

@media (width >= 1280px) {
  .accordion__panel {
    padding-right: 2rem;
  }
}

.accordion__inner {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.imgtext {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  align-items: center;
  gap: 1.5rem;
  display: grid;
}

.imgtext:last-child {
  margin-bottom: 0;
}

.imgtext__img {
  grid-column: 1 / -1;
}

@media (width >= 767px) {
  .imgtext__img {
    grid-column: span 5 / span 5;
  }
}

@media (width >= 991px) {
  .imgtext__img {
    grid-column: span 6 / span 6;
  }
}

.imgtext__text {
  grid-column: 1 / -1;
}

@media (width >= 767px) {
  .imgtext__text {
    grid-column: span 6 / span 6;
  }
}

@media (width >= 991px) {
  .imgtext__text {
    grid-column: span 5 / span 5;
  }
}

@media (width >= 767px) {
  .imgtext:nth-child(odd) .imgtext__img {
    grid-column-end: auto;
  }

  .imgtext:nth-child(odd) .imgtext__text {
    order: 1;
    grid-column-end: 13;
  }

  .imgtext:nth-child(2n) .imgtext__img {
    grid-column-end: 13;
  }

  .imgtext:nth-child(2n) .imgtext__text {
    order: -1;
  }

  .section--imgtext--inverse .imgtext:nth-child(2n) .imgtext__img {
    grid-column-end: auto;
  }

  .section--imgtext--inverse .imgtext:nth-child(2n) .imgtext__text {
    order: 1;
    grid-column-end: 13;
  }

  .section--imgtext--inverse .imgtext:nth-child(odd) .imgtext__img {
    grid-column-end: 13;
  }

  .section--imgtext--inverse .imgtext:nth-child(odd) .imgtext__text {
    order: -1;
    grid-column-end: auto;
  }
}

.boxes {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1.5rem;
  display: grid;
}

.boxes__item {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(92 138 255 / var(--tw-border-opacity));
  --tw-backdrop-blur: blur(24px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #fcfcfd26;
  border-radius: 1.5rem;
  grid-column: 1 / -1;
  padding: 5rem 2.5rem 4rem;
}

.boxes__item h3 {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.boxes__item p {
  --tw-text-opacity: 1;
  color: rgb(214 226 255 / var(--tw-text-opacity));
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.left-0 {
  left: 0;
}

.left-2 {
  left: .5rem;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-12 {
  top: 3rem;
}

.top-2 {
  top: .5rem;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2\] {
  z-index: 2;
}

.order-last {
  order: 9999;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-full {
  grid-column: 1 / -1;
}

.m-6 {
  margin: 1.5rem;
}

.\!my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.\!mb-2 {
  margin-bottom: .5rem !important;
}

.\!mb-4 {
  margin-bottom: 1rem !important;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-\[20rem\] {
  margin-bottom: -20rem;
}

.-mb-\[2rem\] {
  margin-bottom: -2rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-\[24rem\] {
  margin-top: -24rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[-10\%\] {
  margin-bottom: -10%;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[-50vw\] {
  margin-left: -50vw;
}

.ml-auto {
  margin-left: auto;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-5\%\] {
  margin-top: -5%;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.\!inline-flex {
  display: inline-flex !important;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[375\/150\] {
  aspect-ratio: 375 / 150;
}

.aspect-\[4\/3\] {
  aspect-ratio: 4 / 3;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-\[1em\] {
  height: 1em;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-\[9\.25rem\] {
  max-height: 9.25rem;
}

.min-h-\[20rem\] {
  min-height: 20rem;
}

.min-h-\[3\.9rem\] {
  min-height: 3.9rem;
}

.min-h-\[5\.5rem\] {
  min-height: 5.5rem;
}

.\!w-80 {
  width: 20rem !important;
}

.\!w-auto {
  width: auto !important;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-96 {
  width: 24rem;
}

.w-\[10rem\] {
  width: 10rem;
}

.w-\[1440px\] {
  width: 1440px;
}

.w-\[1ch\] {
  width: 1ch;
}

.w-\[200\%\] {
  width: 200%;
}

.w-\[30rem\] {
  width: 30rem;
}

.w-\[32rem\] {
  width: 32rem;
}

.w-\[7rem\] {
  width: 7rem;
}

.w-\[8rem\] {
  width: 8rem;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[calc\(100\%\+var\(--container-side-gap\)\)\] {
  width: calc(100% + var(--container-side-gap));
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[66\%\] {
  min-width: 66%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-32 {
  max-width: 8rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[calc\(100vw-var\(--container-gutter\)\)\] {
  max-width: calc(100vw - var(--container-gutter));
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.table-fixed {
  table-layout: fixed;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-100\%\] {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-15\%\] {
  --tw-translate-x: -15%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[10\%\] {
  --tw-translate-y: 10%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.cursor-default {
  cursor: default;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[2\.25rem\] {
  border-radius: 2.25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-\[2rem\] {
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-black\/5 {
  border-color: #0000000d;
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(28 100 242 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-pure-200\/50 {
  border-color: #f2f4f780;
}

.border-pure-300 {
  --tw-border-opacity: 1;
  border-color: rgb(234 236 240 / var(--tw-border-opacity));
}

.border-pure-400 {
  --tw-border-opacity: 1;
  border-color: rgb(208 213 221 / var(--tw-border-opacity));
}

.border-pure-500 {
  --tw-border-opacity: 1;
  border-color: rgb(152 162 179 / var(--tw-border-opacity));
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(235 240 255 / var(--tw-border-opacity));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(214 226 255 / var(--tw-border-opacity));
}

.border-sky-200\/20 {
  border-color: #d6e2ff33;
}

.bg-black\/5 {
  background-color: #0000000d;
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.bg-fairway-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 194 130 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-pure-0 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
}

.bg-pure-0\/\[\.07\] {
  background-color: #fcfcfd12;
}

.bg-pure-0\/\[\.15\] {
  background-color: #fcfcfd26;
}

.bg-pure-0\/\[0\.07\] {
  background-color: #fcfcfd12;
}

.bg-pure-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-pure-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 244 247 / var(--tw-bg-opacity));
}

.bg-sky-0 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 244 255 / var(--tw-bg-opacity));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 240 255 / var(--tw-bg-opacity));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 226 255 / var(--tw-bg-opacity));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 12 41 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-yolk-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 180 31 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-gradient-sky-radial {
  background-image: radial-gradient(197.89% 163.03% at 3.28% 2.71%, #5c8aff 0%, #2766ff 53.38%);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-yolk-radial {
  background-image: radial-gradient(96.31% 325.96% at 1.91% 100%, #ffda92 0%, #ffb41f 55.1%);
}

.from-\[\#BFCDFF\]\/0 {
  --tw-gradient-from: #bfcdff00 var(--tw-gradient-from-position);
  --tw-gradient-to: #bfcdff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#DADFEB\] {
  --tw-gradient-from: #dadfeb var(--tw-gradient-from-position);
  --tw-gradient-to: #dadfeb00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[var\(--c\\_app-light\)\] {
  --tw-gradient-from: var(--c_app-light) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-c_bg {
  --tw-gradient-from: var(--c_bg) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-200 {
  --tw-gradient-from: #d6e2ff var(--tw-gradient-from-position);
  --tw-gradient-to: #d6e2ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-400 {
  --tw-gradient-from: #5c8aff var(--tw-gradient-from-position);
  --tw-gradient-to: #5c8aff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-transparent {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-\[percentage\:5\%_95\%\] {
  --tw-gradient-via-position: 5% 95%;
}

.to-\[\#BFCDFF\] {
  --tw-gradient-to: #bfcdff var(--tw-gradient-to-position);
}

.to-\[\#DADFEB\] {
  --tw-gradient-to: #dadfeb var(--tw-gradient-to-position);
}

.to-\[var\(--c\\_app-mid\)\] {
  --tw-gradient-to: var(--c_app-mid) var(--tw-gradient-to-position);
}

.to-c_bg {
  --tw-gradient-to: var(--c_bg) var(--tw-gradient-to-position);
}

.to-sky-200 {
  --tw-gradient-to: #d6e2ff var(--tw-gradient-to-position);
}

.to-sky-600 {
  --tw-gradient-to: #0045f5 var(--tw-gradient-to-position);
}

.stroke-current {
  stroke: currentColor;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-left {
  object-position: left;
}

.object-top {
  object-position: top;
}

.p-1 {
  padding: .25rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.\!pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[26rem\] {
  padding-bottom: 26rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[24rem\] {
  padding-top: 24rem;
}

.pt-\[6\.5rem\] {
  padding-top: 6.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.\!text-sky-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(214 226 255 / var(--tw-text-opacity)) !important;
}

.\!text-sky-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(0 12 41 / var(--tw-text-opacity)) !important;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.text-brick-500 {
  --tw-text-opacity: 1;
  color: rgb(253 106 64 / var(--tw-text-opacity));
}

.text-c_body {
  color: var(--c_body);
}

.text-c_headline {
  color: var(--c_headline);
}

.text-c_subtle {
  color: var(--c_subtle);
}

.text-fairway-600 {
  --tw-text-opacity: 1;
  color: rgb(19 194 130 / var(--tw-text-opacity));
}

.text-fairway-700 {
  --tw-text-opacity: 1;
  color: rgb(15 149 99 / var(--tw-text-opacity));
}

.text-ghost-500 {
  --tw-text-opacity: 1;
  color: rgb(151 154 191 / var(--tw-text-opacity));
}

.text-ghost-600 {
  --tw-text-opacity: 1;
  color: rgb(92 95 132 / var(--tw-text-opacity));
}

.text-ghost-700 {
  --tw-text-opacity: 1;
  color: rgb(67 69 96 / var(--tw-text-opacity));
}

.text-ghost-900 {
  --tw-text-opacity: 1;
  color: rgb(8 9 12 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-pure-0 {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.text-pure-500 {
  --tw-text-opacity: 1;
  color: rgb(152 162 179 / var(--tw-text-opacity));
}

.text-pure-600 {
  --tw-text-opacity: 1;
  color: rgb(102 112 133 / var(--tw-text-opacity));
}

.text-pure-700 {
  --tw-text-opacity: 1;
  color: rgb(71 84 103 / var(--tw-text-opacity));
}

.text-pure-800 {
  --tw-text-opacity: 1;
  color: rgb(29 41 57 / var(--tw-text-opacity));
}

.text-sky-0 {
  --tw-text-opacity: 1;
  color: rgb(241 244 255 / var(--tw-text-opacity));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(235 240 255 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(214 226 255 / var(--tw-text-opacity));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(173 197 255 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity));
}

.text-yolk-600 {
  --tw-text-opacity: 1;
  color: rgb(255 180 31 / var(--tw-text-opacity));
}

.text-yolk-700 {
  --tw-text-opacity: 1;
  color: rgb(245 163 0 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.overline {
  text-decoration-line: overline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.transition-all {
  transition-property: all;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.transition-transform {
  transition-property: transform;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-75 {
  transition-delay: 75ms;
}

.duration-\[250ms\] {
  transition-duration: .25s;
}

.duration-\[300ms\] {
  transition-duration: .3s;
}

.duration-\[600ms\] {
  transition-duration: .6s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.text-xs {
  letter-spacing: 0;
  font-size: .75rem;
  line-height: 1.25;
}

.\!text-sm {
  letter-spacing: 0 !important;
  font-size: .83rem !important;
  line-height: 1.75 !important;
}

.text-sm {
  letter-spacing: 0;
  font-size: .83rem;
  line-height: 1.75;
}

.text-base {
  letter-spacing: 0;
  font-size: clamp(.9rem, .321429vw + .835714rem, 1.125rem);
  line-height: 1.75;
}

.text-lg {
  letter-spacing: 0;
  font-size: 1.1rem;
  line-height: 1.75;
}

.text-xl {
  letter-spacing: 0;
  font-size: 1.38rem;
  line-height: 1.5;
}

.text-2xl {
  letter-spacing: 0;
  font-size: 1.66rem;
  line-height: 1.5;
}

.text-3xl {
  letter-spacing: -.03em;
  font-size: 2.21rem;
  line-height: 1.25;
}

.text-7xl {
  font-size: clamp(2.30921rem, 3.61155vw + 1.5869rem, 4.83729rem);
  line-height: 1;
}

.\[display\:inherit\] {
  display: inherit;
}

.\[justify-content\:inherit\] {
  justify-content: inherit;
}

html {
  scroll-behavior: smooth;
}

html.jump {
  scroll-behavior: auto;
}

.markdown {
  --tw-text-opacity: 1;
  color: rgb(71 84 103 / var(--tw-text-opacity));
}

.markdown > * + * {
  margin-top: 3rem;
}

.markdown > :is(p, ul, ol) + :is(h2, h3, h4, h5) {
  margin-top: 6rem;
}

.markdown > p + p {
  margin-top: .75rem;
}

.markdown h1 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .markdown h1 {
    margin-bottom: 2rem;
  }
}

.markdown h1 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.markdown h1 {
  letter-spacing: -.03em;
  margin-bottom: 2rem;
  font-size: 2.76rem;
  line-height: 1.25;
}

@media (width >= 767px) {
  .markdown h1 {
    margin-bottom: 3rem;
  }
}

[class*="theme--"] .markdown h1:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .markdown h1:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.markdown h2 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .markdown h2 {
    margin-bottom: 2rem;
  }
}

.markdown h2 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.markdown h2 {
  letter-spacing: -.03em;
  font-size: 2.21rem;
  line-height: 1.25;
}

[class*="theme--"] .markdown h2:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .markdown h2:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.markdown h3 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .markdown h3 {
    margin-bottom: 2rem;
  }
}

.markdown h3 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.markdown h3 {
  letter-spacing: 0;
  font-size: 1.66rem;
  line-height: 1.5;
}

[class*="theme--"] .markdown h3:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .markdown h3:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.markdown h4 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .markdown h4 {
    margin-bottom: 2rem;
  }
}

.markdown h4 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.markdown h4 {
  letter-spacing: 0;
  font-size: 1.38rem;
  line-height: 1.5;
}

[class*="theme--"] .markdown h4:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .markdown h4:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.markdown h5 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .markdown h5 {
    margin-bottom: 2rem;
  }
}

.markdown h5 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.markdown h5 {
  letter-spacing: 0;
  font-size: 1.1rem;
  line-height: 1.75;
}

[class*="theme--"] .markdown h5:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .markdown h5:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

@media (width >= 767px) {
  .footer .markdown h5 {
    margin-bottom: 3rem;
  }
}

.markdown a {
  text-decoration-line: underline;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.markdown a:hover {
  --tw-brightness: brightness(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.markdown ul {
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  list-style: disc;
}

.markdown ul li {
  --tw-text-opacity: 1;
  color: rgb(67 69 96 / var(--tw-text-opacity));
}

.markdown ol {
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  list-style: auto;
}

.markdown ol li {
  --tw-text-opacity: 1;
  color: rgb(67 69 96 / var(--tw-text-opacity));
}

.markdown.article {
  --tw-text-opacity: 1;
  color: rgb(71 84 103 / var(--tw-text-opacity));
  font-weight: 500;
}

.markdown.article p > img:only-child {
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  overflow: hidden;
}

.markdown.article h3 {
  color: currentColor;
}

.markdown.article ul {
  margin-left: 0;
  list-style-type: none;
}

.markdown.article ul li {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 3rem;
  position: relative;
}

.markdown.article ul li:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.046875' width='30' height='30' rx='5' fill='%2313C282'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.7071 10.3398C22.0976 10.7303 22.0976 11.3635 21.7071 11.754L13.7071 19.754C13.3166 20.1445 12.6834 20.1445 12.2929 19.754L8.29289 15.754C7.90237 15.3635 7.90237 14.7303 8.29289 14.3398C8.68342 13.9492 9.31658 13.9492 9.70711 14.3398L13 17.6327L20.2929 10.3398C20.6834 9.94925 21.3166 9.94925 21.7071 10.3398Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .25rem;
  position: absolute;
  left: 0;
}

.markdown.article ol {
  margin-left: 1.25rem;
  list-style: auto;
}

.markdown.article ol li {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
  position: relative;
}

.markdown.article blockquote {
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  border-radius: 1rem;
  padding: 2rem 3rem;
}

@media (width >= 991px) {
  .markdown.article blockquote {
    padding: 3rem 6rem;
  }
}

.markdown.article blockquote p:last-child {
  margin-bottom: 0 !important;
}

.markdown.article blockquote:not(:last-child) {
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.markdown.article blockquote:not(:last-child) p {
  text-align: center;
  color: currentColor;
  letter-spacing: 0;
  font-size: 1.38rem;
  font-weight: 500;
  line-height: 1.5;
}

.markdown.article blockquote:not(:last-child) p:first-child:before {
  content: "";
  background: url("data:image/svg+xml,%3Csvg width='87' height='60' viewBox='0 0 87 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.7058 14.2502C33.439 14.3625 30.0424 15.0643 27.516 16.3556C24.9896 17.5907 22.9965 19.5557 21.5368 22.2505C27.3756 22.2505 32.2039 23.6821 36.0215 26.5454C39.8954 29.4087 41.8323 34.0685 41.8323 40.5248C41.8323 42.939 41.4112 45.2969 40.5691 47.5988C39.7269 49.9006 38.4357 51.9498 36.6953 53.7464C34.9548 55.4868 32.8495 56.9465 30.3792 58.1255C27.909 59.2483 25.0738 59.8097 21.8737 59.8097C15.5296 59.8097 10.4207 57.7605 6.54683 53.6622C2.72915 49.5076 0.820312 43.9776 0.820312 37.0721C0.820312 33.2544 1.35366 29.4087 2.42037 25.5348C3.54322 21.6049 5.5082 17.9837 8.31531 14.6713C11.1224 11.3028 14.912 8.35529 19.6841 5.82889C24.4562 3.30248 30.4635 1.534 37.7058 0.523438V14.2502ZM82.5916 14.2502C78.3248 14.3625 74.9282 15.0643 72.4018 16.3556C69.8754 17.5907 67.8542 19.5557 66.3384 22.2505C72.2334 22.2505 77.0897 23.6821 80.9073 26.5454C84.7812 29.4087 86.7181 34.0685 86.7181 40.5248C86.7181 42.939 86.2689 45.2969 85.3707 47.5988C84.5285 49.9006 83.2372 51.9498 81.4968 53.7464C79.8126 55.4868 77.7353 56.9465 75.265 58.1255C72.7948 59.2483 69.9315 59.8097 66.6753 59.8097C60.3873 59.8097 55.3064 57.7605 51.4326 53.6622C47.5588 49.5076 45.6219 43.9776 45.6219 37.0721C45.6219 33.2544 46.1552 29.4087 47.2219 25.5348C48.3448 21.6049 50.3098 17.9837 53.1169 14.6713C55.9801 11.3028 59.7978 8.35529 64.5699 5.82889C69.342 3.30248 75.3493 1.534 82.5916 0.523438V14.2502Z' fill='%235C8AFF'/%3E%3C/svg%3E%0A") 0 0 / contain no-repeat;
  width: 5rem;
  height: 4rem;
  margin: 1rem auto 2rem;
  display: block;
}

.markdown.article blockquote:not(:last-child) em {
  letter-spacing: 0;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  font-size: .83rem;
  font-style: normal;
  line-height: 1.75;
  display: flex;
}

.markdown.article blockquote:not(:last-child) em img {
  border-radius: 9999px;
  width: 3rem;
  margin-right: .75rem;
}

.min-h-screen {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.h-screen {
  height: calc(var(--vh, 1vh) * 100) !important;
}

.invisible-element {
  visibility: hidden !important;
  display: none !important;
}

.deepl {
  transition-duration: .6s;
}

body.scroll-down:not(.scroll-top) .deepl {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
}

.deepl__popup.open {
  visibility: visible;
  --tw-translate-x: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

:root {
  --container-gutter: 4vw;
  --container-max-w: 1180px;
}

@media (width >= 1280px) {
  :root {
    --container-gutter: 5vw;
  }
}

@media (width >= 1440px) {
  :root {
    --container-gutter: 3rem;
  }
}

:root {
  --container-side-gap: calc(max(var(--container-gutter), (100vw - var(--container-max-w)) / 2));
}

.pricing-comparison {
  contain: paint;
}

.pricing-comparison td, .pricing-comparison th {
  padding: .5rem;
  font-size: 13.5px;
  font-weight: 500;
}

.pricing-comparison td:first-child, .pricing-comparison th:first-child {
  padding-left: 2rem;
}

@media (width >= 991px) {
  .pricing-comparison td:first-child, .pricing-comparison th:first-child {
    padding-left: 3rem;
  }

  .pricing-comparison td:last-child, .pricing-comparison th:last-child {
    padding-right: 3rem;
  }
}

.pricing-comparison thead th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pricing-comparison thead th:first-child {
  width: 12rem;
}

.pricing-comparison thead th:not(:first-child) {
  width: 100%;
}

@media (width >= 991px) {
  .pricing-comparison thead th:not(:first-child) {
    width: 7rem;
  }

  .pricing-comparison thead th:last-child {
    width: 9rem;
  }
}

.pricing-comparison tbody th {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
}

.pricing-comparison tbody td {
  --tw-text-opacity: 1;
  color: rgb(92 95 132 / var(--tw-text-opacity));
}

.pricing-comparison tbody td:first-of-type i.fa, .pricing-comparison tbody td:first-of-type i.fas {
  --tw-text-opacity: 1;
  color: rgb(173 197 255 / var(--tw-text-opacity));
}

.pricing-comparison tbody td:nth-of-type(2) i.fa, .pricing-comparison tbody td:nth-of-type(2) i.fas {
  --tw-text-opacity: 1;
  color: rgb(19 194 130 / var(--tw-text-opacity));
}

.pricing-comparison tbody td:nth-of-type(3) i.fa, .pricing-comparison tbody td:nth-of-type(3) i.fas {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

.pricing-comparison tbody td:nth-of-type(4) i.fa, .pricing-comparison tbody td:nth-of-type(4) i.fas {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
}

.pricing-comparison tbody td:nth-of-type(5) i.fa, .pricing-comparison tbody td:nth-of-type(5) i.fas {
  --tw-text-opacity: 1;
  color: rgb(255 180 31 / var(--tw-text-opacity));
}

.pricing-comparison tbody i.fas.fa-times-circle {
  --tw-text-opacity: 1 !important;
  color: rgb(255 90 95 / var(--tw-text-opacity)) !important;
}

.pricing-comparison__section-header {
  position: relative;
}

.pricing-comparison__section-header th {
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}

.pricing-comparison__section-header th > h3 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .pricing-comparison__section-header th > h3 {
    margin-bottom: 2rem;
  }
}

.pricing-comparison__section-header th > h3 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.pricing-comparison__section-header th > h3 {
  letter-spacing: 0;
  font-size: 1.38rem;
  line-height: 1.5;
}

[class*="theme--"] .pricing-comparison__section-header th > h3:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .pricing-comparison__section-header th > h3:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.pricing-comparison__section-header th > h3 {
  margin-bottom: .5rem;
}

.pricing-comparison__section-header td {
  display: none !important;
}

@media (width >= 991px) {
  .pricing-comparison__section-header td {
    display: table-cell !important;
  }
}

.pricing-comparison__row:last-child td, .pricing-comparison__row:last-child th, .pricing-comparison__last-of-section td, .pricing-comparison__last-of-section th {
  padding-bottom: 3rem;
}

.pricing-comparison td:first-of-type, .pricing-comparison td:nth-of-type(3), .pricing-comparison td:nth-of-type(4), .pricing-comparison td:nth-of-type(5) {
  display: none;
}

@media (width >= 991px) {
  .pricing-comparison td:first-of-type, .pricing-comparison td:nth-of-type(3), .pricing-comparison td:nth-of-type(4), .pricing-comparison td:nth-of-type(5) {
    display: table-cell;
  }
}

.pricing-comparison[data-selected="1"] td:first-of-type, .pricing-comparison[data-selected="2"] td:nth-of-type(2), .pricing-comparison[data-selected="3"] td:nth-of-type(3), .pricing-comparison[data-selected="4"] td:nth-of-type(4), .pricing-comparison[data-selected="5"] td:nth-of-type(5) {
  display: table-cell;
}

.pricing-comparison[data-selected="1"] td:not(:first-of-type), .pricing-comparison[data-selected="2"] td:not(:nth-of-type(2)), .pricing-comparison[data-selected="3"] td:not(:nth-of-type(3)), .pricing-comparison[data-selected="4"] td:not(:nth-of-type(4)), .pricing-comparison[data-selected="5"] td:not(:nth-of-type(5)) {
  display: none;
}

@media (width >= 991px) {
  .pricing-comparison[data-selected="1"] td:not(:first-of-type), .pricing-comparison[data-selected="2"] td:not(:nth-of-type(2)), .pricing-comparison[data-selected="3"] td:not(:nth-of-type(3)), .pricing-comparison[data-selected="4"] td:not(:nth-of-type(4)), .pricing-comparison[data-selected="5"] td:not(:nth-of-type(5)) {
    display: table-cell;
  }
}

.pricing-comparison__select-wrap {
  position: relative;
}

.pricing-comparison__select-wrap:after {
  pointer-events: none;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  position: absolute;
  top: 50%;
  right: 1rem;
}

#pricing-comparison__select {
  cursor: pointer;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgb(19 194 130 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

#pricing-comparison__select[data-selected="1"] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 226 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

#pricing-comparison__select[data-selected="2"] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 194 130 / var(--tw-bg-opacity));
}

#pricing-comparison__select[data-selected="3"] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
}

#pricing-comparison__select[data-selected="4"] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 12 41 / var(--tw-bg-opacity));
}

#pricing-comparison__select[data-selected="5"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 180 31 / var(--tw-bg-opacity));
}

.navbar {
  z-index: 20;
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  transition-property: transform;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

body.scroll-down:not(.scroll-top) .navbar {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

body.scroll-top .navbar {
  transition-duration: 75ms;
}

.navbar {
  box-shadow: 0 13px 8px -10px #002ea30d;
}

.navbar:after {
  content: "";
  z-index: -1;
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  position: absolute;
  inset: 0;
}

.navbar__btn svg path {
  transition-property: all;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
}

body.nav--open .navbar__btn svg path:first-child {
  transform: translate(6px, -1px)rotate(45deg);
}

body.nav--open .navbar__btn svg path:nth-child(2) {
  opacity: 0;
  transform: translateX(10px);
}

body.nav--open .navbar__btn svg path:nth-child(3) {
  transform: translate(-8px, 7px)rotate(-45deg);
}

.navbar__menu > ul > li {
  align-items: center;
  display: inline-flex;
}

.navbar__menu > ul > li > a {
  letter-spacing: 0;
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(67 69 96 / var(--tw-text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .83rem;
  font-weight: 500;
  line-height: 1.75;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.navbar__menu > ul > li > a:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.navbar__menu > ul > li > a > span {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: block;
  position: relative;
}

.navbar__menu > ul > li > a > span:after {
  transform-origin: 0;
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
  content: "";
  border-radius: .125rem;
  width: 100%;
  height: 2px;
  transition-property: transform;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  display: block;
  position: absolute;
  bottom: 0;
}

.navbar__menu > ul > li > a:hover, .navbar__menu > ul > li > a:focus, .navbar__menu > ul > li > a:active {
  --tw-text-opacity: 1;
  color: rgb(8 9 12 / var(--tw-text-opacity));
}

.navbar__menu > ul > li.current > a span:after {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.navbar__menu > ul > li .subnav {
  z-index: -10;
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(241 244 255 / var(--tw-bg-opacity));
  opacity: 0;
  justify-content: center;
  transition-property: all;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 13px 8px -10px #002ea31a;
}

.navbar__menu > ul > li .subnav li:not(:last-child):after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgb(214 226 255 / var(--tw-bg-opacity));
  width: 1px;
  height: 2rem;
}

.navbar__menu > ul > li .subnav a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.navbar__menu > ul > li .subnav a:focus, .navbar__menu > ul > li .subnav a:active {
  --tw-bg-opacity: 1;
  background-color: rgb(235 240 255 / var(--tw-bg-opacity));
}

.navbar__menu > ul > li:focus > ul.subnav, .navbar__menu > ul > li:focus-within > ul.subnav, .navbar__menu > ul > li > a:focus + ul.subnav {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.navbar__menu > ul > li:focus > div.submeganav, .navbar__menu > ul > li:focus-within > div.submeganav, .navbar__menu > ul > li > a:focus + div.submeganav {
  visibility: visible;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.navbar__menu > ul > li.current > ul.onpage {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.tooltip.navbar .submeganav > .tooltip-arrow:before, [role="tooltip"].navbar .submeganav > [data-popper-arrow]:before, [role="tooltip"].navbar .submeganav > [data-popper-arrow]:after {
  visibility: hidden;
}

.navbar .submeganav {
  visibility: hidden;
  z-index: 10;
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  opacity: 0;
  letter-spacing: 0;
  border-radius: .75rem;
  justify-content: center;
  width: 62rem;
  max-width: calc(100% - 2rem);
  margin-top: -.5rem;
  margin-left: auto;
  margin-right: auto;
  font-size: .83rem;
  line-height: 1.75;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: grid;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.navbar .submeganav > * {
  grid-column: 1 / -1;
}

@media (width >= 991px) {
  .navbar .submeganav {
    grid-template-columns: 25% 75%;
  }
}

.navbar .submeganav {
  box-shadow: 0 12px 16px -4px #10182814, 0 4px 6px -2px #10182808;
}

.navbar .submeganav__content, .navbar .submeganav__aside {
  flex-direction: column;
  padding: 1.5rem;
  display: flex;
}

.navbar .submeganav__aside, .navbar .submeganav__aside + .submeganav__content {
  grid-column: span 1 / span 1;
}

.navbar .submeganav__heading {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-weight: 600;
  display: block;
}

.navbar .submeganav__heading:not(:first-child) {
  margin-top: 1rem;
}

.navbar .submeganav__list, .navbar .submeganav__cardlist {
  grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  gap: .25rem;
  display: grid;
}

.navbar .submeganav__list > li a, .navbar .submeganav__cardlist > li a {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  border-radius: .5rem;
  gap: .75rem;
  padding: .75rem;
  display: flex;
}

.navbar .submeganav__list > li a:hover, .navbar .submeganav__cardlist > li a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.navbar .submeganav__list > li a:focus, .navbar .submeganav__list > li a:active, .navbar .submeganav__cardlist > li a:focus, .navbar .submeganav__cardlist > li a:active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.navbar .submeganav__list > li span, .navbar .submeganav__cardlist > li span {
  font-weight: 600;
}

.navbar .submeganav__list > li i, .navbar .submeganav__cardlist > li i {
  --tw-bg-opacity: 1;
  background-color: rgb(235 240 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  display: flex;
}

.navbar .submeganav__list > li img, .navbar .submeganav__cardlist > li img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: .5rem;
  width: 8rem;
}

.navbar .submeganav__list > li p, .navbar .submeganav__cardlist > li p {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  letter-spacing: 0;
  font-size: .75rem;
  line-height: 1.25;
}

.navbar .submeganav__cardlist {
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 26rem), 1fr));
  display: grid;
}

.navbar .submeganav__asidelist {
  flex-direction: column;
  gap: .25rem;
  display: flex;
}

.navbar .submeganav__asidelist li > a {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-weight: 600;
}

.navbar .submeganav__footer {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  justify-content: center;
  padding: .5rem 1.5rem;
  display: flex;
}

@media (width >= 991px) {
  .navbar .submeganav__footer {
    justify-content: flex-end;
  }
}

.navbar .submeganav__footer .btn {
  letter-spacing: 0 !important;
  font-size: .83rem !important;
  line-height: 1.75 !important;
}

.navbar .submeganav__list__footer {
  justify-content: center;
  display: flex;
}

@media (width >= 991px) {
  .navbar .submeganav__list__footer {
    justify-content: flex-end;
  }
}

.navbar .submeganav__list__footer .btn {
  padding-bottom: 0;
  letter-spacing: 0 !important;
  font-size: .83rem !important;
  line-height: 1.75 !important;
}

.navbar__langs a {
  letter-spacing: 0;
  --tw-text-opacity: 1;
  color: rgb(151 154 191 / var(--tw-text-opacity));
  font-size: .83rem;
  font-weight: 500;
  line-height: 1.75;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.navbar__langs a:hover {
  --tw-text-opacity: 1;
  color: rgb(67 69 96 / var(--tw-text-opacity));
}

.navbar__langs a.active {
  cursor: default;
  --tw-text-opacity: 1;
  color: rgb(8 9 12 / var(--tw-text-opacity));
}

.tooltip.navbar__respo > .tooltip-arrow:before, [role="tooltip"].navbar__respo > [data-popper-arrow]:before, [role="tooltip"].navbar__respo > [data-popper-arrow]:after {
  visibility: hidden;
}

.navbar__respo {
  pointer-events: none;
  visibility: hidden;
  z-index: -5;
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  opacity: 0;
  padding-top: 4rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 991px) {
  .navbar__respo {
    display: none;
  }
}

.navbar__respo {
  height: calc(100 * var(--vh));
}

.navbar__respo__inner {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: .5rem;
  display: flex;
  overflow: auto;
}

body.nav--open .navbar__respo {
  pointer-events: auto;
  visibility: visible;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
}

.navbar__respo nav {
  padding-bottom: 2rem;
}

.navbar__respo nav > ul {
  flex-direction: column;
}

.navbar__respo nav > ul > li {
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  transition-property: all;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  display: flex;
}

.navbar__respo nav > ul > li > a {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: .75rem 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.75;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.navbar__respo nav > ul > li > a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.navbar__respo nav > ul > li > a span {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar__respo nav > ul > li .fa-angle-down {
  transition-property: transform;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
}

.navbar__respo nav > ul > li:focus .fa-angle-down, .navbar__respo nav > ul > li:focus-within .fa-angle-down {
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.navbar__respo nav > ul > li:last-child a {
  border-bottom-width: 0;
}

.navbar__respo nav > ul > li .submeganav {
  visibility: visible;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 1;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: none;
  position: static;
}

.navbar__respo nav > ul > li .submeganav__content, .navbar__respo nav > ul > li .submeganav__aside {
  padding: .75rem;
}

.navbar__respo nav > ul > li .submeganav__list, .navbar__respo nav > ul > li .submeganav__cardlist {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.navbar__respo nav > ul > li .subnav {
  columns: 2;
  background-color: rgb(252 252 253 / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  padding: 1.5rem 2rem;
  display: none;
}

.navbar__respo nav > ul > li .subnav li {
  -webkit-column-break-inside: avoid;
}

.navbar__respo nav > ul > li .subnav li a {
  letter-spacing: 0;
  color: rgb(252 252 253 / var(--tw-text-opacity));
  --tw-text-opacity: .7;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: .83rem;
  font-weight: 400;
  line-height: 1.75;
}

.navbar__respo nav > ul > li .subnav li a:hover {
  opacity: .9;
}

.navbar__respo nav > ul > li .subnav li.current, .navbar__respo nav > ul > li.current a {
  opacity: 1;
}

.navbar__respo nav > ul > li:hover a {
  opacity: .9;
}

.navbar__respo nav > ul > li:focus > .submeganav, .navbar__respo nav > ul > li:focus-within > .submeganav, .navbar__respo nav > ul > li > a:focus + .submeganav {
  display: block;
}

.navbar__respo .navbar__socials {
  letter-spacing: -.03em;
  gap: 1rem;
  font-size: 2.21rem;
  line-height: 1.25;
  display: flex;
}

.navbar__respo .navbar__langs {
  margin-right: -.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar__respo .navbar__langs a {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
  opacity: .4;
  transition-property: opacity;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.navbar__respo .navbar__langs a:hover {
  opacity: 1;
}

.navbar__respo .navbar__langs a.active {
  cursor: default;
  opacity: 1;
}

address {
  font-style: normal;
}

@media (width >= 767px) {
  .footer .h5 {
    margin-bottom: 3rem;
  }
}

.footer__list li {
  letter-spacing: 0;
  font-size: .83rem;
  font-weight: 500;
  line-height: 1.75;
}

.footer__list li a {
  color: inherit;
  margin-bottom: .5rem;
  display: inline-block;
}

.video-js {
  border-radius: 24px;
  box-shadow: 0 0 6px #0003, 1px 1px 23px #00000026;
}

.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  line-height: inherit;
  width: 2em;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fab.fa-pull-left, .fal.fa-pull-left, .far.fa-pull-left, .fas.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fab.fa-pull-right, .fal.fa-pull-right, .far.fa-pull-right, .fas.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical, .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270 {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adobe:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-analytics:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-crate:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-alt-down:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-arrow-alt-from-left:before {
  content: "";
}

.fa-arrow-alt-from-right:before {
  content: "";
}

.fa-arrow-alt-from-top:before {
  content: "";
}

.fa-arrow-alt-left:before {
  content: "";
}

.fa-arrow-alt-right:before {
  content: "";
}

.fa-arrow-alt-square-down:before {
  content: "";
}

.fa-arrow-alt-square-left:before {
  content: "";
}

.fa-arrow-alt-square-right:before {
  content: "";
}

.fa-arrow-alt-square-up:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-arrow-alt-to-left:before {
  content: "";
}

.fa-arrow-alt-to-right:before {
  content: "";
}

.fa-arrow-alt-to-top:before {
  content: "";
}

.fa-arrow-alt-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-from-bottom:before {
  content: "";
}

.fa-arrow-from-left:before {
  content: "";
}

.fa-arrow-from-right:before {
  content: "";
}

.fa-arrow-from-top:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-square-down:before {
  content: "";
}

.fa-arrow-square-left:before {
  content: "";
}

.fa-arrow-square-right:before {
  content: "";
}

.fa-arrow-square-up:before {
  content: "";
}

.fa-arrow-to-bottom:before {
  content: "";
}

.fa-arrow-to-left:before {
  content: "";
}

.fa-arrow-to-right:before {
  content: "";
}

.fa-arrow-to-top:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-atom-alt:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-barcode-alt:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-betamax:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-biking-mountain:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-alt:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-book-spells:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-border-style-alt:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-alt:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-box-fragile:before {
  content: "";
}

.fa-box-full:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-up:before {
  content: "";
}

.fa-box-usd:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-boxes-alt:before {
  content: "";
}

.fa-boxing-glove:before {
  content: "";
}

.fa-brackets:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calculator-alt:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-edit:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camcorder:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-alt:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-car-mechanic:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-caret-circle-down:before {
  content: "";
}

.fa-caret-circle-left:before {
  content: "";
}

.fa-caret-circle-right:before {
  content: "";
}

.fa-caret-circle-up:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cctv:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-chart-pie-alt:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-cheeseburger:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-bishop-alt:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-chess-clock-alt:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-king-alt:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-knight-alt:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-pawn-alt:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-queen-alt:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chess-rook-alt:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-double-down:before {
  content: "";
}

.fa-chevron-double-left:before {
  content: "";
}

.fa-chevron-double-right:before {
  content: "";
}

.fa-chevron-double-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-square-down:before {
  content: "";
}

.fa-chevron-square-left:before {
  content: "";
}

.fa-chevron-square-right:before {
  content: "";
}

.fa-chevron-square-up:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-coffee-togo:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-alt-check:before {
  content: "";
}

.fa-comment-alt-dollar:before {
  content: "";
}

.fa-comment-alt-dots:before {
  content: "";
}

.fa-comment-alt-edit:before {
  content: "";
}

.fa-comment-alt-exclamation:before {
  content: "";
}

.fa-comment-alt-lines:before {
  content: "";
}

.fa-comment-alt-medical:before {
  content: "";
}

.fa-comment-alt-minus:before {
  content: "";
}

.fa-comment-alt-music:before {
  content: "";
}

.fa-comment-alt-plus:before {
  content: "";
}

.fa-comment-alt-slash:before {
  content: "";
}

.fa-comment-alt-smile:before {
  content: "";
}

.fa-comment-alt-times:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-edit:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-comment-times:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-comments-alt-dollar:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-construction:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-conveyor-belt-alt:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-cowbell-more:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-cricket:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-curling:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-debug:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-desktop-alt:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dewpoint:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digging:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-diploma:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-drone-alt:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-dryer-alt:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-eclipse-alt:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-h-alt:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ellipsis-v-alt:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-engine-warning:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-square:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-farm:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-field-hockey:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-certificate:before {
  content: "";
}

.fa-file-chart-line:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-edit:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-search:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-file-times:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-film-alt:before {
  content: "";
}

.fa-film-canister:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-alt:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flame:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flask-poison:before {
  content: "";
}

.fa-flask-potion:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-fog:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-folder-times:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-fragile:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frosty-head:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-game-board-alt:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gamepad-alt:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-glass-champagne:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glasses-alt:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-receiving:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-heart:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-usd:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-haykal:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-head-vr:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heart-circle:before {
  content: "";
}

.fa-heart-rate:before {
  content: "";
}

.fa-heart-square:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-home-alt:before {
  content: "";
}

.fa-home-heart:before {
  content: "";
}

.fa-home-lg:before {
  content: "";
}

.fa-home-lg-alt:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-flood:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-humidity:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-icons-alt:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-inbox-in:before {
  content: "";
}

.fa-inbox-out:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-industry-alt:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-info-square:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-inventory:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-island-tropical:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-landmark-alt:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-layer-minus:before {
  content: "";
}

.fa-layer-plus:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-location-circle:before {
  content: "";
}

.fa-location-slash:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-alt:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-lock-open-alt:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-loveseat:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luchador:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-marker-alt-slash:before {
  content: "";
}

.fa-map-marker-check:before {
  content: "";
}

.fa-map-marker-edit:before {
  content: "";
}

.fa-map-marker-exclamation:before {
  content: "";
}

.fa-map-marker-minus:before {
  content: "";
}

.fa-map-marker-plus:before {
  content: "";
}

.fa-map-marker-question:before {
  content: "";
}

.fa-map-marker-slash:before {
  content: "";
}

.fa-map-marker-smile:before {
  content: "";
}

.fa-map-marker-times:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-mind-share:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-hexagon:before {
  content: "";
}

.fa-minus-octagon:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-mobile-android:before {
  content: "";
}

.fa-mobile-android-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-money-check-edit:before {
  content: "";
}

.fa-money-check-edit-alt:before {
  content: "";
}

.fa-monitor-heart-rate:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-alt:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-music-alt:before {
  content: "";
}

.fa-music-alt-slash:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-oil-temp:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-page-break:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-brush-alt:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-pallet-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-paragraph-rtl:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-parking-circle:before {
  content: "";
}

.fa-parking-circle-slash:before {
  content: "";
}

.fa-parking-slash:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paw-alt:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-paintbrush:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-pennant:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-person-carry:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-laptop:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-alt:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-hexagon:before {
  content: "";
}

.fa-plus-octagon:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-presentation:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-print-search:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-question-square:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-rabbit-fast:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-radio-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-rectangle-landscape:before {
  content: "";
}

.fa-rectangle-portrait:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-repeat-1-alt:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-retweet-alt:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salad:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-sax-hot:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-scalpel-path:before {
  content: "";
}

.fa-scanner:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shipping-timed:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signal-1:before {
  content: "";
}

.fa-signal-2:before {
  content: "";
}

.fa-signal-3:before {
  content: "";
}

.fa-signal-4:before {
  content: "";
}

.fa-signal-alt:before {
  content: "";
}

.fa-signal-alt-1:before {
  content: "";
}

.fa-signal-alt-2:before {
  content: "";
}

.fa-signal-alt-3:before {
  content: "";
}

.fa-signal-alt-slash:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-signal-stream:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-ski-jump:before {
  content: "";
}

.fa-ski-lift:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sledding:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-sliders-h-square:before {
  content: "";
}

.fa-sliders-v:before {
  content: "";
}

.fa-sliders-v-square:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-plus:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snooze:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowmobile:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-shapes-down:before {
  content: "";
}

.fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-sort-shapes-up:before {
  content: "";
}

.fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-sort-size-down:before {
  content: "";
}

.fa-sort-size-down-alt:before {
  content: "";
}

.fa-sort-size-up:before {
  content: "";
}

.fa-sort-size-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-soup:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-sparkles:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablet-android:before {
  content: "";
}

.fa-tablet-android-alt:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tachometer-alt-average:before {
  content: "";
}

.fa-tachometer-alt-fast:before {
  content: "";
}

.fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-tachometer-alt-slow:before {
  content: "";
}

.fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-tachometer-average:before {
  content: "";
}

.fa-tachometer-fast:before {
  content: "";
}

.fa-tachometer-fastest:before {
  content: "";
}

.fa-tachometer-slow:before {
  content: "";
}

.fa-tachometer-slowest:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tally:before {
  content: "";
}

.fa-tanakh:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-tasks-alt:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-frigid:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-hot:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-thunderstorm:before {
  content: "";
}

.fa-thunderstorm-moon:before {
  content: "";
}

.fa-thunderstorm-sun:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-tilde:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-times-hexagon:before {
  content: "";
}

.fa-times-octagon:before {
  content: "";
}

.fa-times-square:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-alt:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-tombstone-alt:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-trash-undo:before {
  content: "";
}

.fa-trash-undo-alt:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-tree-alt:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-triangle-music:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-trophy-alt:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-truck-couch:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-tv-alt:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-usd-circle:before {
  content: "";
}

.fa-usd-square:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-chart:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-hard-hat:before {
  content: "";
}

.fa-user-headset:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-md-chat:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-class:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-crown:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-fork:before {
  content: "";
}

.fa-utensil-knife:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-utensils-alt:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vhs:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-nay:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-wand-magic:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-warehouse-alt:before {
  content: "";
}

.fa-washer:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-water-lower:before {
  content: "";
}

.fa-water-rise:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-waveform-path:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-webcam:before {
  content: "";
}

.fa-webcam-slash:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wifi-1:before {
  content: "";
}

.fa-wifi-2:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-wind-warning:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-window-alt:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("fa-regular-400.f19dee79.eot");
  src: url("fa-regular-400.f19dee79.eot#iefix") format("embedded-opentype"), url("fa-regular-400.d06597d8.woff2") format("woff2"), url("fa-regular-400.b163b0c8.woff") format("woff"), url("fa-regular-400.18812345.ttf") format("truetype"), url("fa-regular-400.094a7f5c.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("fa-solid-900.7aa0e8c1.eot");
  src: url("fa-solid-900.7aa0e8c1.eot#iefix") format("embedded-opentype"), url("fa-solid-900.3215c42b.woff2") format("woff2"), url("fa-solid-900.783c953e.woff") format("woff"), url("fa-solid-900.ff15c9c4.ttf") format("truetype"), url("fa-solid-900.f7a7d8d2.svg#fontawesome") format("svg");
}

.fa, .fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("fa-brands-400.654bc63d.eot");
  src: url("fa-brands-400.654bc63d.eot#iefix") format("embedded-opentype"), url("fa-brands-400.f6794f01.woff2") format("woff2"), url("fa-brands-400.fdf628d6.woff") format("woff"), url("fa-brands-400.ba160a6d.ttf") format("truetype"), url("fa-brands-400.09cbd7c7.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

if-not-language + if-language {
  display: none;
}

never-translate#languages button {
  max-width: 9.5ch;
  overflow: hidden;
}

html:not(.no-js) [data-slide-in] {
  transition-property: transform, opacity;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

html:not(.no-js) [data-slide-in]:not(.in-viewport):not(.above-viewport) {
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
}

html:not(.no-js) [data-fade-in] {
  transition-property: transform, opacity;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

html:not(.no-js) [data-fade-in]:not(.in-viewport):not(.above-viewport) {
  --tw-scale-x: 98%;
  --tw-scale-y: 98%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
}

#CookiebotWidget, #crisp-chatbox {
  z-index: 10 !important;
}

@media (width >= 991px) {
  .md\:container {
    max-width: calc(var(--container-max-w)  + 2 * var(--container-gutter));
    padding-inline: var(--container-gutter);
    width: 100%;
    margin-inline: auto;
  }

  .md\:container--wide {
    --container-max-w: 1440px;
    --container-gutter: inherit;
  }
}

.\[\&\>h2\]\:h2 > h2 {
  --tw-text-opacity: 1;
  color: rgb(0 12 41 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-weight: 800;
}

@media (width >= 767px) {
  .\[\&\>h2\]\:h2 > h2 {
    margin-bottom: 2rem;
  }
}

.\[\&\>h2\]\:h2 > h2 em {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
  font-style: normal;
}

.\[\&\>h2\]\:h2 > h2 {
  letter-spacing: -.03em;
  font-size: 2.21rem;
  line-height: 1.25;
}

[class*="theme--"] .\[\&\>h2\]\:h2 > h2:not([class*="text-"]) {
  color: var(--c_headline);
}

[class*="theme--"] .\[\&\>h2\]\:h2 > h2:not([class*="text-"]) em:not([class*="text-"]) {
  color: var(--c_accent);
}

.after\:pointer-events-none:after {
  content: var(--tw-content);
  pointer-events: none;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:h-6:after {
  content: var(--tw-content);
  height: 1.5rem;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:bg-gradient-to-b:after {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.after\:from-transparent:after {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-pure-100:after {
  content: var(--tw-content);
  --tw-gradient-to: #f9fafb var(--tw-gradient-to-position);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-pure-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-sky-0:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 244 255 / var(--tw-bg-opacity));
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(235 240 255 / var(--tw-bg-opacity));
}

.hover\:bg-sky-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(214 226 255 / var(--tw-bg-opacity));
}

.hover\:bg-sky-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(92 138 255 / var(--tw-bg-opacity));
}

.hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(39 102 255 / var(--tw-bg-opacity));
}

.hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 69 245 / var(--tw-bg-opacity));
}

.hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 23 82 / var(--tw-bg-opacity));
}

.hover\:bg-sky-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 12 41 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-yolk-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 194 71 / var(--tw-bg-opacity));
}

.hover\:bg-yolk-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 180 31 / var(--tw-bg-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.hover\:text-fairway-500:hover {
  --tw-text-opacity: 1;
  color: rgb(22 219 147 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-sky-400:hover {
  --tw-text-opacity: 1;
  color: rgb(92 138 255 / var(--tw-text-opacity));
}

.hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:drop-shadow-lg:hover {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:bg-ghost-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 246 / var(--tw-bg-opacity));
}

.disabled\:text-ghost-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(151 154 191 / var(--tw-text-opacity));
}

.disabled\:opacity-30:disabled {
  opacity: .3;
}

.not-viewport\:-translate-x-5:not(.in-viewport):not(.above-viewport) {
  --tw-translate-x: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.not-viewport\:translate-x-5:not(.in-viewport):not(.above-viewport) {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.not-viewport\:translate-y-5:not(.in-viewport):not(.above-viewport) {
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.not-viewport\:scale-90:not(.in-viewport):not(.above-viewport) {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.not-viewport\:opacity-0:not(.in-viewport):not(.above-viewport) {
  opacity: 0;
}

.dark\:border-blue-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity));
}

.dark\:border-gray-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.dark\:border-transparent:is(.dark *) {
  border-color: #0000;
}

.dark\:bg-blue-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

.dark\:bg-gray-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.dark\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.dark\:bg-gray-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:bg-gray-800\/50:is(.dark *) {
  background-color: #1f293780;
}

.dark\:bg-opacity-80:is(.dark *) {
  --tw-bg-opacity: .8;
}

.dark\:text-blue-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:hover\:bg-blue-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-gray-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-gray-800:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:hover\:text-blue-500:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

.dark\:hover\:text-gray-300:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 600px) {
  .xs\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xs\:block {
    display: block;
  }

  .xs\:w-1\/2 {
    width: 50%;
  }

  .xs\:flex-grow-0 {
    flex-grow: 0;
  }

  .xs\:columns-2 {
    columns: 2;
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:justify-center {
    justify-content: center;
  }

  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xs\:text-center {
    text-align: center;
  }
}

@media (width >= 767px) {
  .sm\:static {
    position: static;
  }

  .sm\:-order-1 {
    order: -1;
  }

  .sm\:order-none {
    order: 0;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:-mb-\[40\%\] {
    margin-bottom: -40%;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-\[150\%\] {
    width: 150%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-\[32rem\] {
    max-width: 32rem;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-\[auto\,1fr\,auto\] {
    grid-template-columns: auto 1fr auto;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:gap-12 {
    gap: 3rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:pb-\[15\%\] {
    padding-bottom: 15%;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }
}

@media (width >= 991px) {
  .md\:pointer-events-none {
    pointer-events: none;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:inset-0 {
    inset: 0;
  }

  .md\:z-10 {
    z-index: 10;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:\!col-end-13 {
    grid-column-end: 13 !important;
  }

  .md\:col-end-13 {
    grid-column-end: 13;
  }

  .md\:col-end-8 {
    grid-column-end: 8;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:\!mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .md\:\!mb-\[-5vw\] {
    margin-bottom: -5vw !important;
  }

  .md\:\!mt-28 {
    margin-top: 7rem !important;
  }

  .md\:-ml-3 {
    margin-left: -.75rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mb-\[-5\%\] {
    margin-bottom: -5%;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:table-header-group {
    display: table-header-group;
  }

  .md\:hidden {
    display: none;
  }

  .md\:aspect-\[1440\/400\] {
    aspect-ratio: 1440 / 400;
  }

  .md\:h-\[110\%\] {
    height: 110%;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:min-h-\[3em\] {
    min-height: 3em;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-\[17rem\] {
    width: 17rem;
  }

  .md\:w-\[50vw\] {
    width: 50vw;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-14 {
    --tw-translate-x: 3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-\[1fr\,auto\,1fr\] {
    grid-template-columns: 1fr auto 1fr;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:rounded-\[2\.25rem\] {
    border-radius: 2.25rem;
  }

  .md\:rounded-b-\[4rem\] {
    border-bottom-right-radius: 4rem;
    border-bottom-left-radius: 4rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-28 {
    padding: 7rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pl-3 {
    padding-left: .75rem;
  }

  .md\:pl-\[calc\(\(100\%\+1\.5rem\)\/3\)\] {
    padding-left: calc(33.3333% + .5rem);
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-\[13rem\] {
    padding-top: 13rem;
  }

  .md\:text-right {
    text-align: right;
  }
}

@media (width >= 1280px) {
  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:text-sm {
    letter-spacing: 0;
    font-size: .83rem;
    line-height: 1.75;
  }
}

@media (width >= 1440px) {
  .xl\:absolute {
    position: absolute;
  }

  .xl\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:-ml-16 {
    margin-left: -4rem;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:translate-x-\[-100\%\] {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:p-7 {
    padding: 1.75rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

.\[\&\:hover_\[data-cover\]\]\:scale-110:hover [data-cover] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>\*\+\*\]\:mb-8 > * + * {
  margin-bottom: 2rem;
}

.\[\&\>\*\+\*\]\:mt-0 > * + * {
  margin-top: 0;
}

.\[\&\>\*\+\*\]\:mt-16 > * + * {
  margin-top: 4rem;
}

.\[\&\>\*\+\*\]\:mt-28 > * + * {
  margin-top: 7rem;
}

.\[\&\>\*\+\*\]\:mt-4 > * + * {
  margin-top: 1rem;
}

.\[\&\>\*\+\*\]\:mt-40 > * + * {
  margin-top: 10rem;
}

.\[\&\>\*\+\*\]\:mt-8 > * + * {
  margin-top: 2rem;
}

.\[\&\>\*\+\.b-section-action\]\:mt-16 > * + .b-section-action {
  margin-top: 4rem;
}

.\[\&\>\*\:empty\]\:hidden > :empty {
  display: none;
}

.\[\&\>\*\:first-child\]\:mt-8 > :first-child {
  margin-top: 2rem;
}

.\[\&\>\*\:last-child\]\:mb-0 > :last-child {
  margin-bottom: 0;
}

.\[\&\>\*\:last-child\]\:mb-4 > :last-child {
  margin-bottom: 1rem;
}

.\[\&\>\*\:not\(\.swiper-slide-active\)\:hover\]\:bg-pure-900\/5 > :not(.swiper-slide-active):hover {
  background-color: #1018280d;
}

.\[\&\>\*\:not\(\.swiper-slide-active\)\]\:bg-transparent > :not(.swiper-slide-active) {
  background-color: #0000;
}

.\[\&\>\*\:not\(\.swiper-slide-active\)\]\:text-pure-600 > :not(.swiper-slide-active) {
  --tw-text-opacity: 1;
  color: rgb(102 112 133 / var(--tw-text-opacity));
}

@media (width >= 767px) {
  .sm\:\[\&\>\*\:not\(\:nth-child\(-n\+4\)\)\]\:border-t > :not(:nth-child(-n+4)) {
    border-top-width: 1px;
  }
}

.\[\&\>\*\:nth-child\(4n\+3\)\]\:pt-0 > :nth-child(4n+3), .\[\&\>\*\:nth-child\(4n\+4\)\]\:pt-0 > :nth-child(4n+4) {
  padding-top: 0;
}

.\[\&\>\*\:nth-child\(4n\+5\)\]\:border-t > :nth-child(4n+5), .\[\&\>\*\:nth-child\(4n\+6\)\]\:border-t > :nth-child(4n+6) {
  border-top-width: 1px;
}

.\[\&\>\*\]\:relative > * {
  position: relative;
}

.\[\&\>\*\]\:col-span-full > * {
  grid-column: 1 / -1;
}

.\[\&\>\*\]\:row-span-full > * {
  grid-row: 1 / -1;
}

.\[\&\>\*\]\:mb-3 > * {
  margin-bottom: .75rem;
}

.\[\&\>\*\]\:ml-5 > * {
  margin-left: 1.25rem;
}

.\[\&\>\*\]\:h-full > * {
  height: 100%;
}

.\[\&\>\*\]\:\!w-24 > * {
  width: 6rem !important;
}

.\[\&\>\*\]\:\!w-auto > * {
  width: auto !important;
}

.\[\&\>\*\]\:w-full > * {
  width: 100%;
}

.\[\&\>\*\]\:flex-shrink-0 > * {
  flex-shrink: 0;
}

.\[\&\>\*\]\:flex-grow > * {
  flex-grow: 1;
}

.\[\&\>\*\]\:cursor-pointer > * {
  cursor: pointer;
}

.\[\&\>\*\]\:rounded-full > * {
  border-radius: 9999px;
}

.\[\&\>\*\]\:border-t-sky-200 > * {
  --tw-border-opacity: 1;
  border-top-color: rgb(214 226 255 / var(--tw-border-opacity));
}

.\[\&\>\*\]\:bg-sky-900 > * {
  --tw-bg-opacity: 1;
  background-color: rgb(0 12 41 / var(--tw-bg-opacity));
}

.\[\&\>\*\]\:object-cover > * {
  object-fit: cover;
}

.\[\&\>\*\]\:p-4 > * {
  padding: 1rem;
}

.\[\&\>\*\]\:px-3 > * {
  padding-left: .75rem;
  padding-right: .75rem;
}

.\[\&\>\*\]\:py-3 > * {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&\>\*\]\:text-pure-0 > * {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.\[\&\>\*\]\:transition-all > * {
  transition-property: all;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.\[\&\>\*\]\:\[break-inside\:avoid-column\] > * {
  break-inside: avoid-column;
}

@media (width >= 600px) {
  .xs\:\[\&\>\*\]\:flex-grow-0 > * {
    flex-grow: 0;
  }
}

@media (width >= 767px) {
  .sm\:\[\&\>\*\]\:\!pt-3 > * {
    padding-top: .75rem !important;
  }
}

@media (width >= 991px) {
  .md\:\[\&\>\*\]\:w-1\/2 > * {
    width: 50%;
  }

  .md\:\[\&\>\*\]\:px-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.\[\&\>\.b-section-header\+\*\]\:mt-16 > .b-section-header + * {
  margin-top: 4rem;
}

.\[\&\>\.btn\]\:flex-grow > .btn {
  flex-grow: 1;
}

@media (width >= 600px) {
  .xs\:\[\&\>\.btn\]\:grow-0 > .btn {
    flex-grow: 0;
  }
}

.\[\&\>a\:hover\]\:bg-pure-700 > a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 84 103 / var(--tw-bg-opacity));
}

.\[\&\>a\]\:bg-pure-500 > a {
  --tw-bg-opacity: 1;
  background-color: rgb(152 162 179 / var(--tw-bg-opacity));
}

.\[\&\>a\]\:text-pure-0 > a {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.\[\&\>img\]\:w-96 > img {
  width: 24rem;
}

@media (width >= 991px) {
  .md\:\[\&\>img\]\:w-full > img {
    width: 100%;
  }
}

.\[\&\>li\.swiper-pagination-bullet-active\]\:bg-white\/20 > li.swiper-pagination-bullet-active {
  background-color: #fff3;
}

.\[\&\>li\.swiper-pagination-bullet-active\]\:text-pure-0 > li.swiper-pagination-bullet-active {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.\[\&\>li\:before\]\:text-sky-500 > li:before {
  --tw-text-opacity: 1;
  color: rgb(39 102 255 / var(--tw-text-opacity));
}

.\[\&\>li\:before\]\:text-yolk-600 > li:before {
  --tw-text-opacity: 1;
  color: rgb(255 180 31 / var(--tw-text-opacity));
}

.\[\&\>li\:hover\]\:text-pure-0 > li:hover {
  --tw-text-opacity: 1;
  color: rgb(252 252 253 / var(--tw-text-opacity));
}

.\[\&\>li\]\:shrink-0 > li {
  flex-shrink: 0;
}

.\[\&\>li\]\:cursor-pointer > li {
  cursor: pointer;
}

.\[\&\>li\]\:rounded-full > li {
  border-radius: 9999px;
}

.\[\&\>li\]\:px-4 > li {
  padding-left: 1rem;
  padding-right: 1rem;
}

.\[\&\>li\]\:py-2 > li {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.\[\&\>li\]\:transition-colors > li {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .33s;
  transition-timing-function: ease-in-out;
}

.\[\&\>p\+p\]\:mb-4 > p + p {
  margin-bottom: 1rem;
}

.\[\&\[data-stuck\=\'true\'\]\>\*\]\:rounded-t-none[data-stuck="true"] > * {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.\[\&_\.card--content\>\*\+\*\]\:mt-4 .card--content > * + * {
  margin-top: 1rem;
}

.\[\&_\.card--content\>\*\]\:mb-0 .card--content > * {
  margin-bottom: 0;
}

.\[\&_\.card--content\]\:p-8 .card--content {
  padding: 2rem;
}

.\[\&_\.card--image\]\:select-none .card--image {
  -webkit-user-select: none;
  user-select: none;
}

.\[\&_\.fas\]\:text-c_accent .fas {
  color: var(--c_accent);
}

.\[\&_img\]\:h-full img {
  height: 100%;
}

.\[\&_img\]\:object-cover img {
  object-fit: cover;
}

body.scroll-up .stuck\:\[body\.scroll-up_\&\]\:translate-y-\[3\.9rem\][data-stuck="true"] {
  --tw-translate-y: 3.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
/*# sourceMappingURL=main.css.map */
